<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading :heading="checklist.name"></page-heading>
    </column>
    <column class="is-narrow">
      <tabs>
        <tab-link name="checklist-template-manager" :params="{ checklist: checklist.uuid }">Overview</tab-link>
        <tab-link name="checklist-template-checkpoints" :params="{ checklist: checklist.uuid }">Checkpoints</tab-link>
        <tab-link name="checklist-template-checklists" :params="{ checklist: checklist.uuid }">Checklists</tab-link>
        <tab-link name="checklist-template-preferences" :params="{ checklist: checklist.uuid }">Preferences</tab-link>
      </tabs>
    </column>
  </columns>
  <router-view />
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('checklist/load', this.$route.params.checklistTemplate)
    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('checklist/clearChecklist')
  },

  computed: mapGetters('checklist', [
    'checklist'
  ])

}
</script>